<template>
  <div class="out-box">
    <left-box></left-box>
    <div class="right-box">
      <div class="inputs">
        <input-text :title="'Email'" v-model="mail" ref="mail" valid-rule="email"></input-text>
        <input-vcode :title="'Verification Code'" v-model="verifyCode" :get-mail="()=>this.mail"
                     vcode-type="resetPassword" ref="verifyCode"></input-vcode>
        <input-pwd :title="'Password'" v-model="pwd" ref="pwd"></input-pwd>
        <input-pwd :title="'Confirm Password'" v-model="confirmPwd" ref="confirmPwd"></input-pwd>
        <a-button class="my-login" @click="onSubmit" :loading="loading"><span>Reset Password</span></a-button>
      </div>
    </div>
  </div>
</template>

<script>
import InputPwd from "@/components/InputPwd";
import InputText from "@/components/InputText";
import InputVcode from "@/components/InputVcode";

export default {
  components: {
    InputPwd,
    InputText,
    InputVcode
  },
  data() {
    return {
      mail: "",
      verifyCode: "",
      pwd: "",
      confirmPwd: "",

      loading: false
    };
  },
  computed: {},
  methods: {
    onSubmit() {
      console.log("onSubmit", this.mail, this.pwd);
      let validResult = true;
      validResult &= this.$refs.mail.validText();
      validResult &= this.$refs.verifyCode.validVcode();
      validResult &= this.$refs.pwd.validPwd();
      validResult &= this.$refs.confirmPwd.validPwd();
      if (this.pwd !== this.confirmPwd) {
        this.$refs.confirmPwd.showErrMsg("Passwords Inconsistent");
        validResult = false;
      }
      if (!validResult) return;
      const form = {
        email: this.mail.trim(),
        verifyCode: this.verifyCode.trim(),
        password: this.pwd.trim()
      };
      console.info("resetPwd onSubmit form={}", form);
      this.$store.dispatch("user/resetPassword", form).then(() => {
        // this.onRegisterSuccess();
        this.$router.push({ path: "/" });
        this.loading = false;
      }).catch(() => {
        // this.onRegisterFail();
        this.loading = false;
      });
    }
  }
};
</script>

<style lang="scss" scoped src="./design.scss"></style>
