<template>
  <div class="my-pwd">
    <div class="my-key">{{ title }}</div>
    <div class="my-value" :class="{'my-input-focus':vcodeOnfocus}">
      <input type="text" placeholder="" v-model="vcode"
             @blur="onVcodeBlur" @focus="onVcodeFocus" @change="onVcodeChange"/>
      <a-link class="my-send-vcode" @click="sendVerifyCode" :disabled="sendState>0">{{ sendLinkText }}</a-link>
    </div>
    <div class="my-value-err-msg">{{ showVcodeErrMsg ? vcodeErrMsg : '' }}</div>
  </div>
</template>

<script>
import {regs} from '@/utils/validate'
import request from "@/utils/request"

export default {
  props: {
    title: {
      type: String,
      required: true
    },
    vcodeType: {
      type: String,
      required: true
    },
    getMail: {
      type: Function,
      required: true
    }
  },
  data() {
    return {
      vcode: '',
      vcodeOnfocus: false,
      showVcodeErrMsg: false,
      vcodeErrMsg: '',
      sendState: 0,
      verifyCodeExpiredSeconds: 0
    }
  },
  computed: {
    sendLinkText() {
      if (this.sendState === 0) return "Verification Code"
      if (this.sendState === 1) return "Sending.."
      return `Resend after ${this.verifyCodeExpiredSeconds}s`
    }
  },
  methods: {
    validVcode() {
      const vcode = (this.vcode || '').trim()
      if (vcode === '') {
        this.vcodeErrMsg = 'Required'
        this.showVcodeErrMsg = true
        return false
      }
      if (!regs.verifyCode.test(vcode)) {
        this.vcodeErrMsg = ''
        this.showVcodeErrMsg = true
        return false
      }
      return true
    },
    onVcodeFocus() {
      console.log('onVcodeFocus')
      this.vcodeOnfocus = true
      this.showVcodeErrMsg = false
    },
    onVcodeBlur() {
      console.log('onVcodeBlur')
      this.vcodeOnfocus = false
      this.validVcode()
    },
    onVcodeChange(e) {
      const oldVal = this.vcode
      const newVal = e.target.value
      this.vcode = newVal
      console.log(`${this.title} vcode:`, oldVal, '->', newVal)
      this.$emit('update:modelValue', this.vcode)
    },
    async sendVerifyCode() {
      console.log('sendVerifyCode')
      this.sendState = 1
      // this.verifyCodeLoading = true
      try {
        const requestConfig = {
          url: '/user/sendVerifyCode',
          method: 'post',
          data: {email: this.getMail(), type: this.vcodeType},
          timeout: 60 * 1000
        }
        const response = await request('/user/sendVerifyCode', requestConfig)
        console.log('sendVerifyCode response:', response)
        if (response.code === 0) {
          this.verifyCodeExpiredSeconds = response.data.expiredSeconds
          const interval = setInterval(() => {
            if (--this.verifyCodeExpiredSeconds <= 0) {
              clearInterval(interval);
              this.sendState = 0;
            }
          }, 1000);
          this.sendState = 2
        } else {
          console.log('sendVerifyCode error!', JSON.stringify(response))
          // this.$message({message: '发送验证码失败', type: 'error'})
          this.sendState = 0
        }
      } catch (e) {
        console.log('sendVerifyCode fail!', e)
        // this.$message({message: '发送验证码失败', type: 'error'})
        this.sendState = 0
      } finally {
        // this.verifyCodeLoading = false
      }
    }
  }
}
</script>

<style lang="scss" scoped>

.my-key {
  font-size: 16px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #333333;
  line-height: 22px;

  //padding: 32px 0 8px 0;
  margin-bottom: 8px;
}

.my-value {
  width: 320px;
  height: 44px;

  background: #FFFFFF;
  border-radius: 6px;
  border: 1px solid #D9DCE0;

  box-sizing: border-box;
  padding: 12px 8px;

  input {
    // 文本
    font-size: 14px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #333333;
    line-height: 20px;

    width: 100%;
    border: none; // 不显示边框
    outline: none; // 不显示有焦点时的边框
    background: rgba(0, 0, 0, 0); // 背景完全透明
  }

  //input:focus {
  //  background: rgba(249, 120, 70, 0.1);
  //  opacity: 1;
  //}

  display: flex;
  flex-direction: row;
}

.my-input-focus {
  background: rgba(249, 120, 70, 0.1);
}

.my-value-err-msg {
  height: 20px;
  font-size: 14px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #E42424;
  line-height: 20px;

  margin: 8px 0 8px 0;
}

.my-pwd {
  input {
    width: auto;
    flex-grow: 1;
  }
}

.my-send-vcode {
  //width: 70px;
  height: 20px;
  font-size: 14px;
  font-family: PingFangSC-Semibold, PingFang SC;
  font-weight: 600;
  color: #2878FF;
  line-height: 20px;
}

</style>
